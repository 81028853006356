var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "control",
    class: {
      'sas': !_vm.state
    }
  }, [_c('div', {
    staticClass: "control-bg"
  }, [_c('img', {
    staticClass: "img-1",
    attrs: {
      "src": "https://keyang2.tuzuu.com/klfront/97af3ccbc83bc09c852dfc5be1d2873a.png"
    }
  }), _c('div', {
    staticClass: "bg-info"
  }, [_c('img', {
    attrs: {
      "src": "https://keyang2.tuzuu.com/klfront/6de1d92fb238864c968c35e87792c6fe.png"
    }
  }), _vm.state ? _c('div', {
    staticClass: "bg-info-1"
  }, [_vm._v("支付成功！")]) : _c('div', {
    staticClass: "bg-info-1"
  }, [_vm._v("权益详情")]), _vm.state ? _c('div', {
    staticClass: "bg-info-2"
  }, [_vm._v("你已成功支付,并且自动成功激活特检卡权益")]) : _vm._e()])]), _c('div', {
    staticClass: "control-info"
  }, [_c('div', {
    staticClass: "control-info-box"
  }, [_c('div', {
    staticClass: "control-info-title"
  }, [_vm._v("特检卡-权益人信息")]), _c('div', {
    staticClass: "control-info-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("权益人")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.detailData.name) + " ")])]), _c('div', {
    staticClass: "control-info-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("联系电话")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.detailData.tel) + " ")])]), _c('div', {
    staticClass: "control-info-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("激活日期")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("setTime")(_vm.detailData.can_use_start)) + " ")])]), _c('div', {
    staticClass: "control-info-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("有效期")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("setTime")(_vm.detailData.can_use_end)) + " ")])])])]), _c('div', {
    staticClass: "footer-btn",
    on: {
      "click": _vm.openkefu
    }
  }, [_vm._v(" 立即预约使用 ")]), _c('ServicePopup', {
    attrs: {
      "kf_qrcode": "https://keyang2.tuzuu.com/klfront/1577ffe7dc2d295c718ef4c1e9de4ab8.png"
    },
    model: {
      value: _vm.kefuShow,
      callback: function ($$v) {
        _vm.kefuShow = $$v;
      },
      expression: "kefuShow"
    }
  }, [_c('div', [_c('p', [_vm._v("长按识别客服二维码")])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };

export default {
    name: "ServicePopup",
    props: ["kf_qrcode", "value"],
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value)
            }
        }
    }
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('van-popup', {
    attrs: {
      "round": "",
      "position": "bottom",
      "closeable": ""
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "kefu"
  }, [_vm._t("default", function () {
    return [_c('p', [_vm._v("长按识别客服二维码")]), _c('p', [_vm._v("获取更多优惠信息")])];
  }), _c('van-image', {
    attrs: {
      "src": _vm.kf_qrcode || 'https://khome2.tuzuu.com/klfront/kefu.png',
      "width": "200px",
      "height": "200px"
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('van-loading', {
          attrs: {
            "type": "spinner",
            "size": "20"
          }
        })];
      },
      proxy: true
    }])
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };

import ServicePopup from "@/components/ServicePopup";
import wxShare from "@/wechat/weChatTile";
import {formatDate} from "@/utils/util";
import {Toast} from "vant";

export default {
    components: {
        ServicePopup
    },
    data() {
        return {
            detailData: {},
            kefuShow: false,
            state: true
        }
    },
    async created() {
        if (this.$route.query.state == 1) {
            this.state = false
        }
        let res = await axios.get("/qykshop/get_user_card", {
            params: {
                sn: this.$route.params.id
            }
        })
        if (res.code === 1) {
            if (res.data?.name) {
                this.detailData = res.data
            } else {

            }
        }
    },
    methods: {
        openkefu() {
            if (this.detailData.card_status === 2) {
                Toast("您的权益卡正处于等待期")
            } else if (this.detailData.card_status === 3) {
                Toast("您的权益卡已过期")
            } else if (this.detailData.card_status === 1) {
                this.kefuShow = true
            }
        }
    },
    filters: {
        setTime(value) {
            return formatDate(value, 'YYYY年MM月DD日 hh:mm:ss')
        }
    }
}

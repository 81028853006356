import { goodsDetail } from "./config";
/**
 * 日期格式化
 * @param {Date} date
 * @param {string} format 格式
 */
export function formatDate(date = new Date(), format = 'YYYY-MM-DD hh:mm:ss') {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    const YYYY = date.getFullYear().toString();
    const MM = ('0' + (date.getMonth() + 1)).slice(-2);
    const DD = ('0' + date.getDate()).slice(-2);
    const hh = ('0' + date.getHours()).slice(-2);
    const mm = ('0' + date.getMinutes()).slice(-2);
    const ss = ('0' + date.getSeconds()).slice(-2);

    const result = format
        .replace(/YYYY/, YYYY)
        .replace(/MM/, MM)
        .replace(/DD/, DD)
        .replace(/hh/, hh)
        .replace(/mm/, mm)
        .replace(/ss/, ss);
    return result;
}
/**
 * 给我一个Date日期，进行转换返回
 * @param {*} date Date实例
 */
export function getFormatDate(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    const h = ("0" + date.getHours()).slice(-2);
    const mm = ("0" + date.getMinutes()).slice(-2);
    const s = ("0" + date.getSeconds()).slice(-2);
    return y + "-" + m + "-" + d + " " + h + ":" + mm + ":" + s;
}

/**
 * 根据数据和对应字段拿到商品详情
 * @param {*} data 
 */
export function getGoodsDetail(data) {
    const temp = deepClone(goodsDetail)
    temp.forEach(item => {
        item.content = data[item.name]
    })
    return temp;
}

/**
 * 深克隆
 * @param {*} target 需要克隆的数据
 */
export function deepClone(target) {
    const temp = Array.isArray(target) ? [] : {};
    for (const key in target) {
        if (target.hasOwnProperty(key)) {
            temp[key] = target[key] && typeof target[key] === "object" ? deepClone(target[key]) : target[key];
        }
    }
    return temp;
};

/**
 * 防抖
 * @param {*} func 需要执行的函数
 * @param {*} wait 间隔时间
 */
 export function debounce(func, wait = 300) {
    if (typeof func !== "function") return;
    let timer = null;
    return function (...arg) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, arg);
        }, wait);
    };
}

/**
 * 验证身份证号
 * @param {*} code 
 */
export function validIdentity(code) {
    var city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 "
    };
    var tip = "";
    var pass = true;

    if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
        tip = "身份证号格式错误";
        pass = false;
    } else if (!city[code.substr(0, 2)]) {
        tip = "地址编码错误";
        pass = false;
    } else {
        //18位身份证需要验证最后一位校验位
        if (code.length == 18) {
            code = code.split('');
            //∑(ai×Wi)(mod 11)
            //加权因子
            var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            //校验位
            var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
            var sum = 0;
            var ai = 0;
            var wi = 0;
            for (var i = 0; i < 17; i++) {
                ai = code[i];
                wi = factor[i];
                sum += ai * wi;
            }
            var last = parity[sum % 11];
            if (parity[sum % 11] != code[17]) {
                tip = "校验位错误";
                pass = false;
            }
        }
    }
    return pass;
}